import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { LoadingSpinner, useIntl, Masonry } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import { Container, Row, Col } from 'reactstrap';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="pb-5 ticket-listing mb-5">
      <Helmet>
        <body className={`ticket-listing-page`} />
      </Helmet>
      <Container className="header-image">
        <Row className="mt-5">
          <Col md="12">
            <div className="text-center">
              <img src={require('./assets/images/dlis_typo.png')} alt="" className="img-fluid mt-5 mb-4" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
        </Helmet>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets} type="list" showCity showLocation />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
